import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import HomeIcon from "../../../../assets/images/Map/home.svg";
import CapIcon from "../../../../assets/images/Map/cap.svg";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

export const getAllLocationsOnMap = (
  markersResidences,
  mapRef,
  locations,
  selectedLocation,
  universityTabControl
) => {
  if (locations.length && mapRef.current) {
    // Tüm rezidanslar için kırmızı marker'ları ekleyin
    const bounds = new mapboxgl.LngLatBounds();
    const bounds2 = new mapboxgl.LngLatBounds();

    console.log(locations, "locations ");
    console.log(universityTabControl, "university tab control");
    locations.forEach((loc) => {
      const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
        `<h3>${
          universityTabControl ? loc?.university_title : loc.campus_name
        }</h3>`
      );

      // marker = new mapboxgl.Marker({ color: "#bf0000" })
      const el = document.createElement("div");
      el.style.backgroundImage = !universityTabControl
        ? `url(${HomeIcon})`
        : `url(${CapIcon})`;
      el.style.width = `40px`;
      el.style.height = `40px`;
      el.style.backgroundSize = "100%";
      let marker;

      if (universityTabControl) {
        if (loc?.university_longitude && loc?.university_latitude) {
          marker = new mapboxgl.Marker(el)
            .setLngLat([
              parseFloat(loc?.university_longitude),
              parseFloat(loc?.university_latitude),
            ])
            .setPopup(popup)
            .addTo(mapRef.current);
          bounds.extend([
            parseFloat(loc?.university_longitude),
            parseFloat(loc?.university_latitude),
          ]);
        }
      } else {
        if (loc?.campus_coordinates?.lng && loc?.campus_coordinates?.lat) {
          marker = new mapboxgl.Marker(el)
            .setLngLat([
              parseFloat(loc?.campus_coordinates?.lng),
              parseFloat(loc?.campus_coordinates?.lat),
            ])
            .setPopup(popup)
            .addTo(mapRef.current);
          bounds2.extend([
            parseFloat(loc?.campus_coordinates?.lng),
            parseFloat(loc?.campus_coordinates?.lat),
          ]);
        }
      }

      markersResidences.current.push(marker);
    });

    if (universityTabControl && !bounds.isEmpty()) {
      mapRef.current.fitBounds(bounds, {
        padding: 50, // Kenarlardan boşluk bırakır
        duration: 500,
      });
    } else if (!bounds2.isEmpty()) {
      mapRef.current.fitBounds(bounds2, {
        padding: 50, // Kenarlardan boşluk bırakır
        duration: 500,
      });
    }
  }
};
