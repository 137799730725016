import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import HomeIcon from "../../../../assets/images/Map/home.svg";
import CapIcon from "../../../../assets/images/Map/cap.svg";
import BusIcon from "../../../../assets/images/Map/bus.svg";
import TrainIcon from "../../../../assets/images/Map/train.svg";
import CartIcon from "../../../../assets/images/Map/cart.svg";
import PharmacyIcon from "../../../../assets/images/Map/pharmacy.svg";
import RestaurantIcon from "../../../../assets/images/Map/restaurant.svg";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

export const getSelectedLocationOnMap = (
  markers,
  markersResidences,
  mapRef,
  selectedLocation,
  nearByUniversities,
  universityTabControl,
  locations,
  publicType
) => {
  if (mapRef.current && locations?.length) {
    // Tüm marker'ları kaldırın, sonra yalnızca seçili rezidans ve nearby universities için marker'ları ekleyin

    markers.current.forEach((marker) => marker.remove());
    markers.current = [];

    if (
      selectedLocation &&
      nearByUniversities &&
      markersResidences?.current?.length
    ) {
      markersResidences.current.forEach((marker) => {
        if (marker) {
          marker.remove();
        }
      });

      markersResidences.current = [];
      // Sadece seçili rezidans için kırmızı marker
      const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
        `<h3>${
          universityTabControl
            ? selectedLocation?.university_title
            : selectedLocation.campus_name
        }</h3>`
      );

      const el = document.createElement("div");
      el.style.backgroundImage = !universityTabControl
        ? `url(${HomeIcon})`
        : `url(${CapIcon})`;

      el.style.width = `40px`;
      el.style.height = `40px`;
      el.style.backgroundSize = "100%";
      const marker = new mapboxgl.Marker(el)
        .setLngLat([
          parseFloat(
            universityTabControl
              ? selectedLocation?.university_longitude
              : selectedLocation?.campus_coordinates?.lng
          ),
          parseFloat(
            universityTabControl
              ? selectedLocation?.university_latitude
              : selectedLocation?.campus_coordinates?.lat
          ),
        ])
        .setPopup(popup)
        .addTo(mapRef.current);

      markersResidences.current.push(marker);

      const bounds = new mapboxgl.LngLatBounds();

      // Nearby universities için mavi marker'lar
      nearByUniversities?.forEach((loc) => {
        console.log(loc, "loc here");
        console.log(publicType, "pub type");
        const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
          `<h3>${
            publicType === "bus" ||
            publicType === "train" ||
            publicType === "market" ||
            publicType === "pharmacie" ||
            publicType === "restaurant"
              ? loc?.tags?.name
              : universityTabControl
              ? loc.campus_name
              : loc.university_title
          }</h3>`
        );

        // const marker = new mapboxgl.Marker({ color: "#74d3ea" })
        const el = document.createElement("div");
        el.style.backgroundImage = universityTabControl
          ? publicType === "bus"
            ? `url(${BusIcon})`
            : publicType === "train"
            ? `url(${TrainIcon})`
            : publicType === "market"
            ? `url(${CartIcon})`
            : publicType === "pharmacie"
            ? `url(${PharmacyIcon})`
            : publicType === "restaurant"
            ? `url(${RestaurantIcon})`
            : `url(${HomeIcon})`
          : publicType === "bus"
          ? `url(${BusIcon})`
          : publicType === "train"
          ? `url(${TrainIcon})`
          : publicType === "market"
          ? `url(${CartIcon})`
          : publicType === "pharmacie"
          ? `url(${PharmacyIcon})`
          : publicType === "restaurant"
          ? `url(${RestaurantIcon}`
          : `url(${CapIcon})`;

        el.style.width =
          publicType === "bus" ||
          publicType === "train" ||
          publicType === "market" ||
          publicType === "pharmacie" ||
          publicType === "restaurant"
            ? `20px`
            : `40px`;
        el.style.height =
          publicType === "bus" ||
          publicType === "train" ||
          publicType === "market" ||
          publicType === "pharmacie" ||
          publicType === "restaurant"
            ? `20px`
            : `40px`;
        el.style.backgroundSize = "100%";
        const marker = new mapboxgl.Marker(el)
          .setLngLat([
            parseFloat(
              publicType === "bus" ||
                publicType === "train" ||
                publicType === "market" ||
                publicType === "pharmacie" ||
                publicType === "restaurant"
                ? loc?.lon
                : universityTabControl
                ? loc?.campus_coordinates?.lng
                : loc.university_longitude
            ),
            parseFloat(
              publicType === "bus" ||
                publicType === "train" ||
                publicType === "market" ||
                publicType === "pharmacie" ||
                publicType === "restaurant"
                ? loc?.lat
                : universityTabControl
                ? loc?.campus_coordinates?.lat
                : loc.university_latitude
            ),
          ])
          .setPopup(popup)
          .addTo(mapRef.current);

        bounds.extend(
          publicType === "bus" ||
            publicType === "train" ||
            publicType === "market" ||
            publicType === "pharmacie" ||
            publicType === "restaurant"
            ? [loc?.lon, loc?.lat]
            : universityTabControl
            ? [loc?.campus_coordinates?.lng, loc?.campus_coordinates?.lat]
            : [loc.university_longitude, loc.university_latitude]
        );

        markers.current.push(marker);

        mapRef.current.fitBounds(bounds, {
          padding: 50, // Kenarlardan boşluk bırakır
          duration: 500,
        });
      });
      // Seçili rezidansın koordinatlarını da bounds'a ekleyin
      bounds.extend([
        parseFloat(
          universityTabControl
            ? selectedLocation?.university_longitude
            : selectedLocation?.campus_coordinates?.lng
        ),
        parseFloat(
          universityTabControl
            ? selectedLocation?.university_latitude
            : selectedLocation?.campus_coordinates?.lat
        ),
      ]);

      // Haritayı bounds'a sığdırın
      mapRef.current.fitBounds(bounds, {
        padding: 50, // Kenarlardan boşluk bırakır
        duration: 500,
      });
    }
  }
};
