import React, { useEffect, useRef, useState } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import { Box, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { light } from "@mui/material/styles/createPalette";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

function MapTopBooked({
  mapRef,
  locationDatas,
  hoveredLocation,
  setHoveredLocation,
  selectedLocation,
  setSelectedLocation,
  bookingDetails,
  setBookingDetails,
  open
}) {
  const mapContainer2 = useRef(null);
  const lightMode = useSelector((state) => state.mode.lightMode); // Redux state'inden lightMode değerini çekin

  const [lng, setLng] = useState(-0.1278);
  const [lat, setLat] = useState(51.5074);
  const [zoom, setZoom] = useState(8.5);
  const markersRef2 = useRef({}); // Marker'lar için bir ref objesi oluştur

  useEffect(() => {
    console.log(open, "open here");
    if (mapRef.current) {
      // Drawer kapandığında haritayı yeniden boyutlandır
      window.setTimeout(() => mapRef.current.resize(), 500);

      console.log(open, "open here 2");
    }
  }, [open, mapRef]);
  
  useEffect(() => {
    if (localStorage.getItem("lightMode")) {
      mapRef.current = new mapboxgl.Map({
        container: mapContainer2.current,
        style: lightMode
          ? "mapbox://styles/mapbox/outdoors-v12"
          : "mapbox://styles/mapbox/dark-v11",
        projection: "mercator",
        zoom: lightMode ? 0 : 8,
        center: lightMode ? [0.1 , 0.2] : [-0.118092, 51.509865],
      });

      mapRef.current.dragPan.enable();
      mapRef.current.touchZoomRotate.enable();

      mapRef.current.on("move", () => {
        setLng(mapRef.current.getCenter().long?.toFixed(4));
        setLat(mapRef.current.getCenter().lat?.toFixed(4));
        setZoom(mapRef.current.getZoom().toFixed(4));
      });

      // Tüm marker'ları temizle
      Object.keys(markersRef2.current).forEach((key) => {
        markersRef2.current[key].remove();
      });
      markersRef2.current = {};

      // API'den dönen verilerle marker'ları ekleyin
      locationDatas.forEach((location, index) => {
        const marker = new mapboxgl.Marker({
          color:
            selectedLocation?.nationality_name === location.nationality_name
              ? selectedLocation.percentage < 15
                ? `#800000`
                : selectedLocation.percentage < 50
                ? `#ea7f3d`
                : `#44b4e0` // Seçilen marker rengi
              : hoveredLocation?.nationality_name === location.nationality_name
              ? hoveredLocation.percentage < 15
                ? `#800000`
                : hoveredLocation.percentage < 50
                ? `#ea7f3d`
                : `#44b4e0`
              : "#bf0000",
        })
          .setLngLat([location.location.long, location.location.lat])
          .addTo(mapRef.current);

        marker.getElement().addEventListener("mouseenter", () => {
          setHoveredLocation(location);
        });

        marker.getElement().addEventListener("mouseleave", () => {
          setHoveredLocation(null);
        });

        marker.getElement().addEventListener("click", () => {
          setSelectedLocation(location);
        });
        markersRef2.current[`marker-${index}`] = marker;
      });

      const handleResizeAndOrientationChange = () => {
        setTimeout(() => {
          mapRef.current.resize();
        }, 200); // 200ms gecikme ile resize metodunu çağırıyoruz.
      };

      window.addEventListener("resize", handleResizeAndOrientationChange);
      window.addEventListener(
        "orientationchange",
        handleResizeAndOrientationChange
      );

      // Component temizlendiğinde (unmount) olay dinleyicilerini kaldır
      return () => {
        window.removeEventListener("resize", handleResizeAndOrientationChange);
        window.removeEventListener(
          "orientationchange",
          handleResizeAndOrientationChange
        );
      };
    }
  }, [locationDatas, lightMode]);

  useEffect(() => {
    if (selectedLocation && mapRef.current) {
      mapRef.current.flyTo({
        center: [selectedLocation.location.long, selectedLocation.location.lat],
        zoom: 3, // İstenilen zoom seviyesini ayarlayın
        speed: 1.5,
      });
    }

    // Marker'ların rengini güncelle
    // Object.values(markersRef2.current).forEach((marker) => {
    //   const markerElement = marker.getElement();
    //   const location = locationDatas.find(
    //     (loc) =>
    //       loc.location.long === marker.getLngLat().lng &&
    //       loc.location.lat === marker.getLngLat().lat
    //   );

    //   if (location) {
    //     const color =
    //       selectedLocation?.nationality_name === location.nationality_name
    //         ? selectedLocation.percentage < 15
    //           ? `#800000`
    //           : selectedLocation.percentage < 50
    //           ? `#ea7f3d`
    //           : `#44b4e0` // Seçilen marker rengi
    //         : hoveredLocation?.nationality_name === location.nationality_name
    //         ? hoveredLocation.percentage < 15
    //           ? `#800000`
    //           : hoveredLocation.percentage < 50
    //           ? `#ea7f3d`
    //           : `#44b4e0`
    //         : "transparent";
    //     const backgroundColor =
    //       selectedLocation?.nationality_name === location.nationality_name
    //         ? "#800000"
    //         : "transparent";
    //     markerElement.style.background = color;
    //   }
    // });
  }, [selectedLocation]);
  return (
    <Box
      ref={mapContainer2}
      className="map-container"
      sx={{
        height: "500px",
        width: "100%",
        borderRadius: "1rem",
        overflow: "hidden",
        position: "relative",
        "&:before": {
          content: "''",
          position: "absolute",
          visibility: "hidden",
          bottom: "0",
          width: "100%",
          height: "30px",
          background: "#000000",
        },
      }}
    >
      {/* {bookingDetails?.display ? (
        <Box
          sx={{
            zIndex: 11,
            background: "#000000d6",
            width: "100%",
            height: "100%",
            position: "absolute",
            bottom: "0",
            padding: "1rem",
            left: "0",
            color: "#ffffff",
          }}
        >
          <Box
            sx={{
              display: "flex",
              borderBottom: "1px solid #efefef",
              paddingBottom: ".5rem",
              marginBottom: "1rem",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                color: "#ffffff",
              }}
              variant="h6"
            >
              Booking Details
            </Typography>

            <CloseIcon
              onClick={() => (
                setBookingDetails({ display: false, details: null }),
                setSelectedLocation(null)
              )}
              sx={{
                color: "#ffffff",
                cursor: "pointer",
              }}
            />
          </Box>

          <Box>
            <Box sx={{ display: "flex" }}>
              <Typography sx={{ marginRight: ".25rem" }}>Detail 1:</Typography>
              <Typography>Booking Detail 1</Typography>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Typography sx={{ marginRight: ".25rem" }}>Detail 1:</Typography>
              <Typography>Booking Detail 1</Typography>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Typography sx={{ marginRight: ".25rem" }}>Detail 1:</Typography>
              <Typography>Booking Detail 1</Typography>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Typography sx={{ marginRight: ".25rem" }}>Detail 1:</Typography>
              <Typography>Booking Detail 1</Typography>
            </Box>
          </Box>
        </Box>
      ) : null} */}
    </Box>
  );
}

export default MapTopBooked;
